<template>
  <div>
    <div class="flex md:flex-row gap-3 md:items-center justify-between">
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Tìm kiếm') }}</label>
        <el-input
          clearable
          :placeholder="$t('Nhập tên sản phẩm, số đăng ký')"
          v-model="form.searchValue"
          autofocus
        ></el-input>
      </div>
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Phân loại') }}</label>
        <el-select clearable v-model="form.type" filterable placeholder="Chọn">
          <el-option
            v-for="item in PRODUCT_TYPES_DATA"
            :key="item.value"
            :label="item.name_vi"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <!-- <div class="flex gap-1 flex-col md:flex-1">
        <label>{{ $t('Chợ') }}</label>
        <el-select clearable v-model="form.originId" filterable placeholder="Chọn">
          <el-option v-for="item in suppliers" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>-->
      <!-- <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Nhà sản xuất') }}</label>
        <el-select
          clearable
          v-model="form.manufacturerId"
          filterable
          placeholder="Chọn nhà sản xuất"
          no-data-text="Không có dữ liệu"
        >
          <el-option
            v-for="item in manufactures"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Thương hiệu') }}</label>
        <el-select
          clearable
          v-model="form.brandId"
          filterable
          placeholder="Chọn thương hiệu"
          no-data-text="Không có dữ liệu"
        >
          <el-option v-for="item in brands" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Nguồn gốc xuất xứ') }}</label>
        <el-select
          clearable
          v-model="form.originId"
          filterable
          no-data-text="Không có dữ liệu"
          placeholder="Chọn nguồn gốc xuất xứ"
        >
          <el-option v-for="item in origins" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>-->
    </div>
    <!-- <div class="flex md:flex-row gap-3 md:items-center justify-between mt-2">
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Dạng bào chế') }}</label>
        <el-select
          clearable
          v-model="form.dosageId"
          filterable
          placeholder="Chọn dạng bào chế"
          no-data-text="Không có dữ liệu"
        >
          <el-option v-for="item in dosages" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Quy cách đóng gói') }}</label>
        <el-select
          clearable
          v-model="form.packingId"
          filterable
          placeholder="Chọn quy cách đóng gói"
          no-data-text="Không có dữ liệu"
        >
          <el-option
            v-for="item in packingOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="flex gap-1 flex-col md:flex-1 w-full">
        <label>{{ $t('Đơn vị') }}</label>
        <el-select
          clearable
          v-model="form.unitId"
          filterable
          placeholder="Chọn đơn vị"
          no-data-text="Không có dữ liệu"
        >
          <el-option v-for="item in units" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="md:flex-1 w-full"></div>
    </div>-->
  </div>
</template>

<script>
import { debounce } from 'lodash'
import store from '@/store'
import 'element-ui/lib/theme-chalk/index.css'
import { Select, Input, Option } from 'element-ui'
import { PRODUCT_TYPES_DATA } from '@/utils/constant'
export default {
  name: 'FilterProducts',
  components: {
    'el-select': Select,
    'el-input': Input,
    'el-option': Option
  },
  data() {
    return {
      form: {
        searchValue: '',
        manufacturerId: null,
        originId: null,
        brandId: null,
        dosageId: null,
        packingId: null,
        unitId: null,
        type: ''
      },
      manufactures: [],
      origins: [],
      brands: [],
      dosages: [],
      packingOptions: [],
      units: [],
      suppliers: [],
      paramsRequest: {
        page_size: 10000,
        page_num: 1,
        supplier_id: null,
        sort_by: 'created_at',
        order: 'desc'
      },
      currentSupplierID: null,
      PRODUCT_TYPES_DATA
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.handleDebounce()
      }
    }
  },
  async mounted() {
    await this.handleGetMarketplaceByID()
    this.handleFetchData()
  },
  methods: {
    handleDebounce: debounce(function() {
      this.$emit('onChangeFilter', this.form)
    }, 500),
    async handleFetchData() {
      // if (!this.paramsRequest.supplier_id) return
      // this.handleGetMKManufacturers()
      // this.handleGetMKBrands()
      // this.handleGetMKDosages()
      // this.handleGetMKOrigins()
      // this.handleGetMKPackings()
      // this.handleGetMKUnits()

      this.$emit('onLoading', false)
    },
    async handleGetMKManufacturers() {
      try {
        const response = await store.dispatch(
          `configProductPartner/fetchManufacturer`,
          {
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
            page_size: 1000,
            page_num: 1,
            sort_by: 'id',
            order: 'desc'
          }
        )

        this.manufactures = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetMKBrands() {
      try {
        const response = await store.dispatch(
          `configProductPartner/fetchBrand`,
          {
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
            page_size: 1000,
            page_num: 1,
            sort_by: 'id',
            order: 'desc'
          }
        )
        this.brands = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetMKDosages() {
      try {
        const response = await store.dispatch(
          `configProductPartner/fetchDosageForm`,
          {
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
            page_size: 1000,
            page_num: 1,
            sort_by: 'id',
            order: 'desc'
          }
        )

        this.dosages = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetMKOrigins() {
      try {
        const response = await store.dispatch(
          `configProductPartner/fetchOrigin`,
          {
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
            page_size: 1000,
            page_num: 1,
            sort_by: 'id',
            order: 'desc'
          }
        )

        this.origins = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetMKPackings() {
      try {
        const response = await store.dispatch(
          `configProductPartner/fetchSpecification`,
          {
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
            page_size: 1000,
            page_num: 1,
            sort_by: 'id',
            order: 'desc'
          }
        )

        this.packingOptions = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetMKUnits() {
      try {
        const response = await store.dispatch(
          `configProductPartner/fetchUnit`,
          {
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
            page_size: 1000,
            page_num: 1,
            sort_by: 'id',
            order: 'desc'
          }
        )
        this.units = response.data?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetMarketplaceByID() {
      try {
        if (!this.$globalOrg?.id) return

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getMarketplaceByID({
            org_id: this.$globalOrg?.id
          })

        const responseData = response.data || {}

        this.currentSupplierID = responseData?.data?.find(
          item => item?.org_id === this.$globalOrg.id
        )?.id

        const responseSuppliers = responseData?.data?.filter(
          item => item?.id !== this.currentSupplierID
        )
        this.suppliers = responseSuppliers || []

        this.paramsRequest.supplier_id = this.currentSupplierID
        return this.currentSupplierID
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>