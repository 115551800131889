<template>
  <div class="cs-container">
    <el-dialog
      :visible.sync="isOpenModal"
      fullscreen
      center
      title="Danh sách sản phẩm"
    >
      <template slot="title">
        <div class="font-bold uppercase" style="font-size: 20px">
          {{ $t("Danh sách sản phẩm") }}
        </div>
      </template>
      <div>
        <FilterProducts
          @onChangeFilter="handleChangeFilter"
          @onLoading="
            (loading) => {
              isLoading = loading;
            }
          "
        />
        <div class="flex justify-end items-center mb-1 mt-4">
          <el-button
            type="primary"
            :disabled="isLoading"
            @click="handleSelectedAll"
            class="text-white btn"
            size="medium"
            >{{ $t("Chọn tất cả") }}</el-button
          >
        </div>
        <div>
          <el-table
            ref="refDataTable"
            :data="listProducts"
            style="width: 100%"
            border
            row-key="id"
            empty-text="Không có dữ liệu"
            @selection-change="handleUpdateSelectedProducts"
          >
            <el-table-column
              reserve-selection
              type="selection"
            ></el-table-column>
            <el-table-column
              min-width="300"
              property="name"
              :label="$t('Tên sản phẩm')"
            ></el-table-column>
            <el-table-column
              property="sku"
              :label="$t('Số đăng ký')"
              :width="200"
            ></el-table-column>
            <el-table-column
              property="supplier.name"
              :label="$t('Nhà cung cấp')"
              :width="250"
            ></el-table-column>
            <!-- <el-table-column property="ecom_dosage_form.name" :label="$t('Dạng bào chế')"></el-table-column>
            <el-table-column property="ecom_specification.name" :label="$t('Quy cách đóng gói')"></el-table-column>
            <el-table-column property="manufacturer" :label="$t('Nhà sản xuất')"></el-table-column>
            <el-table-column property="brand.name" :label="$t('Thương hiệu')"></el-table-column>-->
          </el-table>
          <Pagination
            class="mt-2 mx-0"
            :meta="metaData"
            :perPage="pagination.pageSize"
            :currentPage="pagination.currentPage"
            :totals="pagination.totalItems"
            @setCurrentPage="handleSetCurrentPage"
            @setPerPage="handleSetPerPage"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="flex justify-end items-center gap-2">
          <el-button @click="isOpenModal = false">{{ $t("Huỷ") }}</el-button>
          <el-button
            type="primary"
            @click="isOpenModal = false"
            :disabled="isLoading"
            class="text-white btn"
            >{{ $t("Xác nhận") }}</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { AppPagination } from '../../../components/Common'
import FilterProducts from "./FilterProducts.vue";
import "element-ui/lib/theme-chalk/index.css";
import { Button, Table, TableColumn, Dialog } from "element-ui";
import appUtils from "@/utils/appUtils";
import Pagination from "@/layouts/components/Pagination.vue";

export default {
  name: "ModalListMkProducts",
  components: {
    FilterProducts,
    Pagination,
    "el-button": Button,
    "el-table": Table,
    "el-table-column": TableColumn,
    "el-dialog": Dialog,
  },
  data() {
    return {
      isOpenModal: false,
      isLoading: false,
      listProducts: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0,
      },
      filterParams: {},
    };
  },
  computed: {
    metaData() {
      const localItemsCount = this.$refs.refDataTable
        ? this.$refs.refDataTable.tableData?.length
        : 0;
      return {
        from:
          this.pagination.pageSize * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.pageSize * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totalItems,
      };
    },
  },
  created() {},
  methods: {
    async handleGetMarketplaceProducts() {
      try {
        const userData = appUtils.getLocalUser();

        const params = {
          page_size: this.pagination.pageSize,
          page_num: this.pagination.currentPage,
          sortBy: "id",
          order: "desc",
          supplier_id: userData.supplierInfo?.id,
          type: this.filterParams.type,
          keyword: this.filterParams?.searchValue,
          brand: this.filterParams.brandId,
          manufacturer_id: this.filterParams?.manufacturerId,
          origin: this.filterParams?.originId,
          specification: this.filterParams?.packingId,
          dosage_form: this.filterParams?.dosageId,
          unit: this.filterParams?.unitId,
        };

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getProducts(params);

        this.listProducts = response.data?.data;
        this.pagination.totalItems = response.data?.page?.total;
      } catch (error) {
        console.log(error);
      }
    },
    handleOpenModal(isOpen) {
      this.isOpenModal = isOpen;
      if (isOpen) {
        this.handleGetMarketplaceProducts();
      }
    },
    handleUpdateSelectedProducts(data) {
      this.$emit("onChangeSelectedProducts", data);
    },
    handleChangeFilter(data) {
      this.filterParams = data;
      this.handleGetMarketplaceProducts();
    },
    handleSelectedAll() {
      this.$emit("onSelectedAll", this.listProducts);
      this.isOpenModal = false;
    },
    handleSetCurrentPage(value) {
      this.pagination.currentPage = value;
      this.handleGetMarketplaceProducts();
    },
    handleSetPerPage(value) {
      this.pagination.pageSize = value;
      this.handleGetMarketplaceProducts();
    },
  },
};
</script>

<style lang="scss" scoped>
.cs-container * {
  color: black;
}

::v-deep {
  thead * {
    color: black !important;
  }
  .el-input__inner {
    border: 1px solid #dcdfe6 !important;
    background-color: white !important;
  }
}
.btn {
  background: #20419b;
  border-color: unset;
}
</style>